import React from "react";
import { graphql } from 'gatsby'
import loadable from "@loadable/component";
import Layout from "../components/layout";

const PropertyHistoryBack = loadable(() => import("../components/PropertyHistoryBack/PropertyHistoryBack"));
const PropertyBannerModule = loadable(() => import("../components/PropertyBannerModule/PropertyBannerModule"));
const PropertyDescription = loadable(() => import("../components/PropertyDescription/PropertyDescription"));
const PropertyDetailsGallery = loadable(() => import("../components/PropertyDetailsGallery/PropertyDetailsGallery"));
const PropertyDetailsMap = loadable(() => import("../components/PropertyDetailsMap/PropertyDetailsMap"));
const FeaturedProperties = loadable(() => import("../components/FeaturedProperties/FeaturedProperties"));
const PatternBanner = loadable(() => import("../components/PatternBanner/PatternBanner"));
const FooterContactMobile = loadable(() => import("../components/FooterContactMobile/FooterContactMobile"));

const PropertyDetail = (props) => {

    const property = props.data.wpTpjProperty
    const otherItems = props.data.allWpTpjProperty.nodes

    return (
        <Layout
            layout={""}
            footertag={"footer-contact"}
        >
            <div className="layout-padding-top"></div>

            <PropertyHistoryBack />

            <PropertyBannerModule property={property} />

            <PropertyDescription property={property} />

            <PropertyDetailsGallery property={property} />

            <PropertyDetailsMap
                lat={property.latitude}
                lng={property.longitude}
            />

            <FeaturedProperties
                tag="property-details"
                properties={otherItems}
            />

            <PatternBanner 
                tag = "brown"
                title = "start your journey"
                desc= "Our team of estate agents are experienced, passionate and creative people who are well connected in their local community."
            />

            <div className="d-md-none">
                <FooterContactMobile />
            </div>
        </Layout>
    )
}

export const query = graphql`
query ($page_id: String) {
    wpTpjProperty(id: {eq: $page_id}) {
      id
      databaseId
      address { address_1 address_2 address_3 address_4 county display_address postcode postcode_1 postcode_2 town }
      availability
      bedrooms
      brochures { caption media_type media_update_date media_url }
      floorplans { caption media_type media_update_date media_url }
      images { caption media_type media_update_date media_url }
      longitude
      latitude
      title
      price
      priceQualifier
      propertyType
      rentFrequency
      status
      summary
      features
      instructionType
      isRemote
    }

    allWpTpjProperty(filter: {status: {eq: "publish"}, id: {ne: "cG9zdDo5MTI="}}, limit: 6) {
        nodes {
            id
            databaseId
            address { address_1 address_2 address_3 address_4 county display_address postcode postcode_1 postcode_2 town }
            availability
            bedrooms
            brochures { caption media_type media_update_date media_url optimised_image_url }
            floorplans { caption media_type media_update_date media_url optimised_image_url }
            images { caption media_type media_update_date media_url optimised_image_url }
            longitude
            latitude
            title
            price
            priceQualifier
            propertyType
            rentFrequency
            status
            summary
            instructionType
            isRemote
        }
      }
  }    
`

export default PropertyDetail